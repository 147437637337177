import React, { useEffect } from "react"
import { isMobileOnly } from "react-device-detect"

const Page = () => {
  useEffect(() => {
    const encodedCurrentUrl = encodeURIComponent(window.location)
    const url = isMobileOnly
      ? `https://atmapp.page.link/?ibi=com%2Eatm%2Eatm%2Edev&isi=1500801270&apn=com%2Eatm%2Eatm%2Edevelopment&link=${encodedCurrentUrl}`
      : `${process.env.URL}/?plaid_oauth=dev`
    window.location.assign(url)
  }, [])

  return null
}

export default Page
